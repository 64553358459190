const project = process.env.REACT_APP_PROJECT || "";
const isProd = !project.includes("dev") ? true : false;

export const stripeRabbitoProdcuts = isProd
  ? [
      {
        id: "prod_IvTZQE9j7H6zJ2",
        name: "Professional",
      },
      {
        id: "prod_IvTYkTsl80Dnrw",
        name: "Enterprise",
      },
      {
        id: "prod_OVtyCAfMMOYse0",
        name: "Manager",
      },
    ]
  : [
      {
        id: "prod_EYMEkUlIKTYvUM",
        name: "Professional",
      },
      {
        id: "prod_IqIALLkJVbvuxS",
        name: "Enterprise",
      },
      {
        id: "prod_ObBsKirUzmrJsQ",
        name: "Media Manager",
      },
    ];
