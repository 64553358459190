import { Listbox } from '@headlessui/react';
import { IoIosArrowDown } from 'react-icons/io';
import CheckBox from './CheckBox'; // Assuming you have a CheckBox component

const ListBox = ({ value, options, onChange, label }) => {
    return (
        <Listbox value={value} onChange={onChange}>
            <div className="relative">
                <Listbox.Button className="flex items-center justify-between w-[80%] m-2 p-1 bg-background-1 focus:outline-none">
                    {value ? options.find(option => option.id === value)?.title : label}
                    <IoIosArrowDown className="w-5 h-5 ml-2" />
                </Listbox.Button>
                <Listbox.Options className="rounded-md mt-1 z-10">
                    {label === "Status" && (
                        <Listbox.Option
                            value=""
                            className={({ active }) =>
                                `capitalize rounded p-1 m-2 cursor-pointer flex items-center ${active ? "bg-primary text-white" : ""}`
                            }
                        >
                            <CheckBox
                                checked={value === ""}
                                onClick={() => onChange("")}
                            />
                            <span className="ml-2">All</span>
                        </Listbox.Option>
                    )}
                    {options.map((option, index) => (
                        <Listbox.Option
                            key={`${index}__${option.id}`}
                            value={option.id}
                            className={({ active }) =>
                                `capitalize rounded p-1 m-2 cursor-pointer flex items-center ${active ? "bg-primary text-white" : ""}`
                            }
                        >
                            <CheckBox
                                checked={value === option.id}
                                onClick={() => onChange(option.id)}
                            />
                            <span className="ml-2">{option.title}</span>
                        </Listbox.Option>
                    ))}
                </Listbox.Options>
            </div>
        </Listbox>
    );
};

export default ListBox;
