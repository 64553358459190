import React, { Fragment } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Dashboard from "./components/Dashboard";
import Login from "./pages/Login";
import Leads from "./pages/Sales";
import { Toaster } from "react-hot-toast";
import NotFound from "./pages/404";
import ReleaseNotes from "./pages/ReleaseNotes";
import Promotions from "./pages/Marketing/Promotions";
import Email from "./pages/Marketing/Email";
import AuthCheck from "./AuthCheck";
import Waitlist from "./components/Waitlist";

const App = () => {
  return (
    <Fragment>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route
          path="/"
          element={
            <AuthCheck>
              <Dashboard />
            </AuthCheck>
          }
        />
          <Route
          path="/waitlist"
          element={
            <AuthCheck>
              <Waitlist />
            </AuthCheck>
          }
        />
        
        <Route
          path="/sales"
          element={
            <AuthCheck>
              <Leads />
            </AuthCheck>
          }
        />
        <Route
          path="/marketing/email"
          element={
            <AuthCheck>
              <Email />
            </AuthCheck>
          }
        />
        <Route
          path="/marketing/promotions"
          element={
            <AuthCheck>
              <Promotions />
            </AuthCheck>
          }
        />
        <Route
          path="/marketing"
          element={<Navigate to="/marketing/promotions" />}
        />
        <Route
          path="/release-notes"
          element={
            <AuthCheck>
              <ReleaseNotes />
            </AuthCheck>
          }
        />
        <Route path="/*" element={<NotFound />} />
      </Routes>
      <Toaster
        position="bottom-left"
        toastOptions={{
          duration: 10000,
          success: {
            style: {
              background: "#ECFDF3",
            },
          },
          error: {
            style: {
              background: "#FEF3F2",
            },
          },
        }}
      />
    </Fragment>
  );
};

export default App;
