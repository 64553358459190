import React from "react";
import c from "classnames";

const Badge = ({ className, lead }) => {
  let { subscription, subscriptionStatus: status, isTrialing } = lead || {};

  const subscriptionStatus = status?.toLowerCase();
  const isAccountDeleted =
    lead.hasOwnProperty("userId") && (lead?.userId === "" || !lead?.userId);

  if (isAccountDeleted) {
    return (
      <div className={c("flex items-center gap-2 flex-row-reverse", className)}>
        <div className="bg-[#FECDCA] text-[#D92D20] max-w-fit px-2 py-0.5 rounded-sm shadow-md">
          <p className="capitalize text-xs font-medium">Deleted</p>
        </div>
      </div>
    );
  }

  return (
    <div className={c("flex items-center gap-2 flex-row-reverse", className)}>
      <div
        className={c(
          " p2 text-white max-w-fit px-2 py-0.5 rounded-sm shadow-md"
        )}
      >
        <p
          className={`capitalize text-xs font-medium ${subscriptionStatus === "canceled" ? "line-through" : ""
            }`}
        >
          {subscription === "pro" ? "Professional" : subscription}
        </p>
      </div>
      {isTrialing && (
        <div className="bg-[#FEDF89] text-[#101828] max-w-fit px-2 py-0.5 rounded-sm shadow-md">
          <p className="capitalize text-xs font-medium">Trial</p>
        </div>
      )}
    </div>
  );
};

export default Badge;
