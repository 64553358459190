import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "next-themes";
import axios from "axios";
import { apiGateway } from "./config";
import { SWRConfig } from "swr";
import { ConfirmationServiceProvider } from "./components/ConfirmationService";
import { SearchProvider } from "./context/SearchContext";
import { SourceFilterProvider } from "./context/SourceFilterContext.js";

axios.defaults.baseURL = apiGateway;
const fetcher = async (resource) => {
  const r = await axios.get(resource, {
    headers: { "x-api-key": process.env.REACT_APP_X_API_KEY_CRM },
  });
  return r.data;
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <ThemeProvider attribute="class">
      <BrowserRouter>
        <SourceFilterProvider>
          <SWRConfig value={{ fetcher: fetcher }}>
            <ConfirmationServiceProvider>
              <SearchProvider>
                <App />
              </SearchProvider>
            </ConfirmationServiceProvider>
          </SWRConfig>
        </SourceFilterProvider>
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
