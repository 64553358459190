
import React, { Fragment } from "react";
import { boardColumns } from "../../constants/board";
import { BsSliders } from "react-icons/bs";
import "../../styles/swiper.css";
import { Menu, Transition } from "@headlessui/react";
import ListBox from "../ListBox";
const FilterDropdownMenu = ({ filters, setFilters, handleSourceChange }) => {
    const sources = [
        { id: 'rabbito', title: 'Rabbito' },
        { id: 'objex', title: 'Objex' },
        { id: 'hbhm', title: 'HBHM' },
        { id: 'mazrnow', title: 'Mazrnow' },
        { id: 'cricketottawa', title: 'Cricket Ottawa' },
        { id: 'objextv', title: 'Objex TV' }
    ];

    const handleDateChange = (value) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            dateRange: value,
        }));
        console.log("Selected date range:", value);
    };

    return (
        <Menu as="div" className="relative inline-block text-left">
            {({ open }) => (
                <>
                    <Menu.Button className="flex items-center justify-center p-2 border border-[#555555] rounded-md hover:border-gray-300">
                        <BsSliders className="w-7 h-5 text-heading-1" />
                    </Menu.Button>
                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Menu.Items className="absolute right-0 top-12 w-40 origin-top-right divide-y divide-border-border-1 rounded-md bg-background-1 shadow-lg border border-border-1 z-40">
                            <div className="py-2  ">
                                {/* Status Listbox */}
                                <div className="md:block hidden">
                                    <ListBox
                                        value={filters.status}
                                        options={boardColumns}
                                        onChange={(value) => setFilters(prev => ({ ...prev, status: value }))}
                                        label="Status"
                                    />
                                </div>
                                <ListBox
                                    value={filters.source}
                                    options={sources}
                                    onChange={(newSource) => {
                                        handleSourceChange(newSource);
                                        setFilters(prev => ({ ...prev, source: newSource }));
                                    }}
                                    label="Source"
                                />
                                <ListBox
                                    label="Date Range"
                                    value={filters.dateRange}
                                    onChange={handleDateChange}
                                    options={[
                                        { id: 'today', title: 'Today' },
                                        { id: 'thisWeek', title: 'This Week' },
                                        { id: 'lastWeek', title: 'Last Week' },
                                        { id: 'thisMonth', title: 'This Month' },
                                        { id: 'lastMonth', title: 'Last Month' },
                                        { id: 'thisYear', title: 'This Year' },
                                        { id: 'lastYear', title: 'Last Year' },
                                    ]}
                                />
                            </div>
                        </Menu.Items>
                    </Transition>
                </>
            )}
        </Menu>
    );
};
export default FilterDropdownMenu;