import React, { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { HiDotsHorizontal } from 'react-icons/hi';
import { ImPencil2, ImBin } from 'react-icons/im';
import Loading from '../Loading';
import { timeSince } from '../../utils/common';


const NotesList = ({ notes, loading, setNote, setSelectedToEdit, handleDeleteNote }) => {
    return (
        <div className="md:mt-4 mt-0 h-auto max-h-32 overflow-auto w-full md:w-[23rem] text-logo2 no-scrollbar">
            {notes?.length ? (
                <ul className="space-y-1 text-logo2 mt-2 list-none">
                    {notes.map((note, index) => {
                        const { id, note: noteText, created_at, updated_at, created_by } = note || {};
                        return (
                            <div
                                key={`${index}__${id}`}
                                className={`flex justify-between text-wrap border-b border-border items-start group ${loading.deleteNote?.has(id) ? 'animate-pulse' : ''
                                    }`}
                            >
                                <li className="list-item list-inside text-wrap text-secondary-txt text-sm md:text-base">
                                    {noteText}{' '}
                                    {updated_at && (
                                        <small className="text-[10px]">updated {timeSince(updated_at)}</small>
                                    )}
                                    <small className="block text-wrap text-[10px]">
                                        Created by <b>{created_by ?? 'N/A'}</b> {timeSince(created_at)}
                                    </small>
                                </li>

                                {loading.deleteNote?.has(id) ? (
                                    <Loading width="4" height="4" />
                                ) : (
                                    <Menu as="div" className="relative group-hover:block">
                                        <Menu.Button className="inline-flex w-full justify-center text-secondary-txt-1 rounded px-1 font-medium focus:outline-none focus:ring-1 focus:ring-offset-1 ring-primary">
                                            <HiDotsHorizontal className="h-5 w-5" aria-hidden="true" />
                                        </Menu.Button>
                                        <Transition
                                            as={Fragment}
                                            enter="transition ease-out duration-100"
                                            enterFrom="transform opacity-0 scale-95"
                                            enterTo="transform opacity-100 scale-100"
                                            leave="transition ease-in duration-75"
                                            leaveFrom="transform opacity-100 scale-100"
                                            leaveTo="transform opacity-0 scale-95"
                                        >
                                            <Menu.Items className="absolute z-50 top-5 right-0 w-32 origin-top-right divide-y divide-border-border rounded-md bg-background-4 shadow-lg focus:outline-none border border-border-1">
                                                <div className="px-1 py-1">
                                                    <Menu.Item>
                                                        {({ active }) => (
                                                            <button
                                                                onClick={() => {
                                                                    setNote(noteText);
                                                                    setSelectedToEdit(note);
                                                                }}
                                                                className={`${active ? 'bg-background-3 text-heading-1' : 'text-label'
                                                                    } group flex gap-1 w-full items-center rounded-md px-2 py-2 text-xs`}
                                                            >
                                                                <ImPencil2 />
                                                                <span>Edit</span>
                                                            </button>
                                                        )}
                                                    </Menu.Item>
                                                    <Menu.Item>
                                                        {({ active }) => (
                                                            <button
                                                                disabled={loading.deleteNote?.has(id)}
                                                                onClick={() => handleDeleteNote(note?.id)}
                                                                className={`${active ? 'bg-background-3 text-heading-1' : 'text-label'
                                                                    } group flex gap-1 w-full items-center rounded-md px-2 py-2 text-xs`}
                                                            >
                                                                <ImBin className="text-red-light-txt" />
                                                                <span className="text-red-light-txt">Delete</span>
                                                            </button>
                                                        )}
                                                    </Menu.Item>
                                                </div>
                                            </Menu.Items>
                                        </Transition>
                                    </Menu>
                                )}
                            </div>
                        );
                    })}
                </ul>
            ) : (
                ''
            )}
        </div>
    );
};

export default NotesList;
