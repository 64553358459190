export const firebaseConfig = {
  apiKey: "AIzaSyBnWShoteAYTG5ltImQBuwjQ1DlmgNd61M",
  authDomain: "rabbito-app.firebaseapp.com",
  projectId: "rabbito-app",
  storageBucket: "rabbito-app.appspot.com",
  messagingSenderId: "1074319032146",
  appId: "1:1074319032146:web:b3ef59b4e398c81114448b",
  measurementId: "G-VK9D9ZCKN4",
};

export const apiGateway = process.env.REACT_APP_API_GATEWAY;
export const marketingApi = process.env.REACT_APP_MARKETING_API;
