export const targetAudience = [
  {
    name: "Not subscribed",
    segmentId: "575c2579-f5a9-4c92-a9ee-6a705f235b15",
  },
  {
    name: "Standard",
    segmentId: "8e7c53a7-5664-493e-9a1d-97743a98d9ba",
  },
  {
    name: "Proffesional",
    segmentId: "e4e800c9-ab10-4963-9d65-6d89a3ac4a28",
  },
  {
    name: "Enterprise",
    segmentId: "e424143a-bad1-4afd-9ec1-f4c7b6bd1f43",
  },
  {
    name: "Media Manager",
    segmentId: "065181e2-6ff3-4dd4-8014-7a42c633d482",
  },
  {
    name: "All Contacts",
    segmentId: "45e1a9e5-db33-40f2-b410-edd9762e63c3",
  }
];
