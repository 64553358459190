import React, { useState } from 'react';
import copy from 'copy-to-clipboard';
import { FiCheckCircle } from 'react-icons/fi';
import { PiCopySimpleLight } from 'react-icons/pi';

const EnrichData = ({ leadData }) => {
    const {
        postType,
        businessName,
        autoIncreamentedId,
        created_at,
        industry,
        postId,
        subscription,
        environment,
        size,
        brand,
        status,
        avatarUrl,
        hippoTrust,
        title,
        extrapolated_email_confidence,
        show_intent,
        photo_url,
        first_name,
        headline,
        twitter_url,
        facebook_url,
        is_likely_to_engage,
        revealed_for_current_team,
        last_name,
        email_status,
        intent_strength,
        github_url,
        organization_id,
        linkedin_url,
        userId,
        seniority,
    } = leadData;

    const basicInfo = {
        'First Name': first_name,
        'Last Name': last_name,
        'User ID': userId,
        'Headline': headline,
    };

    const socialMedia = {
        'Twitter URL': twitter_url,
        'Facebook URL': facebook_url,
        'LinkedIn URL': linkedin_url,
        'GitHub URL': github_url,
    };

    const companyInfo = {
        'Business Name': businessName,
        'Industry': industry,
        'Company Size': size,
        'Brand': brand,
        'Environment': environment,
        'Subscription': subscription,
        'Organization ID': organization_id,
    };

    const additionalInfo = {
        'Post Type': postType,
        'Post ID': postId,
        'Auto Incremented ID': autoIncreamentedId,
        'Created At': created_at?._seconds && new Date(created_at._seconds * 1000).toLocaleString(),
        'Status': status,
        'Avatar URL': avatarUrl,
        'Hippo Trust Score': hippoTrust?.score,
        'Hippo Trust Level': hippoTrust?.level,
        'Title': title,
        'Extrapolated Email Confidence': extrapolated_email_confidence,
        'Show Intent': show_intent,
        'Photo URL': photo_url,
        'Is Likely To Engage': is_likely_to_engage,
        'Revealed For Current Team': revealed_for_current_team,
        'Email Status': email_status,
        'Intent Strength': intent_strength,
        'Seniority': seniority,
    };

    const [copiedField, setCopiedField] = useState(null);

    const handleCopy = (field, e) => {
        e.stopPropagation();
        copy(leadData[field]);
        setCopiedField(field);
        setTimeout(() => setCopiedField(null), 1000);
    };

    const renderFieldGroup = (groupData) => {
        return Object.entries(groupData).map(([label, value]) => {

            const isUrl = label.toLowerCase().includes('url') && value;

            return (
                <div key={label} className="py-2  items-center">
                    <div className='flex'>
                        <span className="font-bold text-secondary-txt mr-2">{label}:</span>
                        {isUrl ? (
                            <a href={value} target="_blank" rel="noopener noreferrer" className="text-blue-500 break-all underline">
                                {value}
                            </a>
                        ) : (
                            <span className='break-normal text-secondary-txt-1'>{value || 'N/A'}</span>
                        )}
                        {label === 'User ID' && (
                            <span
                                className="ml-2 cursor-pointer"
                                onClick={(e) => handleCopy('userId', e)}
                            >
                                {copiedField === 'userId' ? (
                                    <FiCheckCircle className="text-green-500" />
                                ) : (
                                    <PiCopySimpleLight />
                                )}
                            </span>
                        )}
                    </div>
                    <hr className=" border-border border " />
                </div>
            );
        });
    };

    return (
        <div className="p-4 h-96 overflow-y-auto">
            <div>
                <h3 className="text-lg font-semibold mb-2">Basic Information</h3>
                {renderFieldGroup(basicInfo)}
            </div>

            <div>
                <h3 className="text-lg font-semibold mb-2">Social Media</h3>
                {renderFieldGroup(socialMedia)}
            </div>

            <div>
                <h3 className="text-lg font-semibold mb-2">Company Information</h3>
                {renderFieldGroup(companyInfo)}
            </div>

            <div className="mt-8">
                <h3 className="text-lg font-semibold mb-2">Additional Information</h3>
                {renderFieldGroup(additionalInfo)}
            </div>
        </div>
    );
};

export default EnrichData;
