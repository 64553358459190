import React, { createContext, useState, useContext } from "react";

const SourceFilterContext = createContext();

export const SourceFilterProvider = ({ children }) => {
  const [selectedSource, setSelectedSource] = useState("rabbito"); // Default source

  const handleSourceChange = (newSource) => {
    setSelectedSource(newSource);
  };

  const contextValue = {
    selectedSource,
    handleSourceChange,
  };

  return (
    <SourceFilterContext.Provider value={contextValue}>
      {children}
    </SourceFilterContext.Provider>
  );
};

export const useSourceFilter = () => useContext(SourceFilterContext);
