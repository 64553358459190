import React, { useState } from "react";
import SidebarLayout from "../layouts/SidebarLayout";
import Button from "../components/Button";
import axios from "axios";
import toast from "react-hot-toast";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const modules = {
  toolbar: [
    [{ 'header': '1' }],
    [{ 'header': '2' }],
    ["bold"],
    ["italic"],
    ["underline"],
    ["blockquote"],
    [{ list: "bullet" }],
    [{ list: "ordered" }],
    ["link"],]

};

const formats = [
  "header", "bold", "italic", "underline", "blockquote",
  "list", "bullet", "link", "image", "color", "background"
];

const ReleaseNotes = () => {
  const [title, setTitle] = useState("");
  const [notes, setNotes] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const handleChange = (value) => {
    setNotes(value);
    setError(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();


    const releaseData = {
      title: title,
      notes: notes,
    };

    try {
      setLoading(true);

      await axios.post("/notification/singlesends", {
        dynamicTemplateId: process.env.REACT_APP_DYNAMIC_TEMPLATE_ID,
        segmentLists: [process.env.REACT_APP_ALL_CONTACTS_SEGMENT_LIST_ID],
        dynamicTemplateData: {
          features: releaseData,
        },
        name: "Rabbito",
        subject: title ?? "Rabbito | Release Notes",
      });

      setTitle("");
      setNotes("");
      toast.success("Release notes have been successfully processed!");
    } catch (err) {
      const error =
        err?.response?.data?.message || err?.response?.data?.error;
      setError(error || "Something went wrong!");
    } finally {
      setLoading(false);
    }
  };

  return (
    <SidebarLayout>
      <div className="px-3 md:px-6 lg:px-8 py-2 md:py-4 lg:py-6 overflow-x-auto">
        <h2 className="md:text-3xl text-lg font-medium leading-9 text-heading-1">
          Release Notes
        </h2>
        <form
          className="w-full mt-2 md:mt-8 text-label flex flex-col md:gap-3"
          onSubmit={handleSubmit}
        >
          <div className="md:flex-row md:space-y-0 gap-2 text-secondary-txt">
            <label
              htmlFor="title"
              className="block text-sm py-2 font-medium min-w-[90%] md:min-w-[90%]"
            >
              Title
            </label>
            <input
              required
              name="title"
              type="text"
              value={title}
              placeholder=""
              className="appearance-none bg-transparent block md:w-[90%] w-full px-3 py-2 border-2 border-[#555555] rounded-md placeholder-gray-400 sm:text-sm focus:z-10 focus:outline-none focus:border-primary focus:ring-primary"
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div className="md:flex-row md:space-y-0 justify-between gap-2 text-secondary-txt">
            <label
              htmlFor="notes"
              className="block text-sm py-2 font-medium min-w-[280px] md:min-w-[180px]"
            >
              Notes
              <span className="text-xs text-secondary-txt"> (JSON Object)</span>
            </label>
            <ReactQuill
              value={notes}
              onChange={handleChange}
              theme="snow"
              modules={modules}
              formats={formats}
              className="dark:bg-transparent ql-container text-black dark:text-white border-0 block md:w-[90%] mt-2 h-[70%]"
            />
          </div>

          {error && (
            <span className="text-xs text-error-red block mt-10 text-right">
              {error}
            </span>
          )}

          <div className="gap-2 flex  items-center justify-end md:w-[90%] w-full">
            <Button
              className="px-8 py-3"
              disabled={loading || notes.length === 0}
              loading={loading}
              type="submit"
            >
              Send
            </Button>
          </div>
        </form>
      </div>
    </SidebarLayout>
  );
};

export default ReleaseNotes;
