import React, { Fragment, useEffect, useRef, useState } from "react";
import {
  DndContext,
  DragOverlay,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { createPortal } from "react-dom";
import SidebarLayout from "../layouts/SidebarLayout";
import { boardColumns } from "../constants/board";
import { arrayMove } from "@dnd-kit/sortable";
import LeadCard from "../components/board/LeadCard";
import useSWR from "swr";
import axios from "axios";
import { toast } from "react-hot-toast";
import Skeleton from "../components/Skeleton";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";
import io from "socket.io-client";
import { apiGateway } from "../config";
import { useSearch } from "../context/SearchContext";
import { FiDownload } from "react-icons/fi";
import "../styles/swiper.css";
import { Transition } from "@headlessui/react";
import DropDown from '../components/DropDown.js';
import { IoSearchOutline } from "react-icons/io5";
import { debounce } from "../utils/common.js";
import Papa from 'papaparse';
import Button from "../components/Button.js";
import { useSourceFilter } from "../context/SourceFilterContext.js";
import LeadUpload from "../components/sales/LeadUpload.js";
import FilterDropdownMenu from "../components/sales/FilterDropdownMenu.js";
import Column from "../components/sales/Column.js";
function Sales() {
  const [leads, setLeads] = useState([]);
  const [filters, setFilters] = useState(() => {
    const storedFilters =
      typeof window !== "undefined" && localStorage.getItem("filters");
    return storedFilters
      ? JSON.parse(storedFilters)
      : {
        status: "",
        source: "rabbito",
        subscription: "",
      };
  });
  const { selectedSource, handleSourceChange } = useSourceFilter();

  const { search, setSearch } = useSearch();
  const debouncedSetSearch = debounce(setSearch, 1000);
  const { data: { data: allLeads } = {}, error: leadsError, mutate: leadsMutate } = useSWR(
    `crm?source=${selectedSource}${search ? `&search=${search}` : ""}${filters.status ? `&status=${filters.status}` : ""
    }${filters.subscription ? `&subscription=${filters.subscription}` : ""}`
  );


  useEffect(() => {
    const hasHostedLeads = leads.some(lead => lead.status === "SALE" && isWithinLastMonth(lead.updated_at));
    const hasAbandonedLeads = leads.some(lead => lead.status === "ABANDONED");

    if (search && hasHostedLeads && !hasAbandonedLeads) {
      setSelectedTab("Hosted");
    } else if (search && !hasHostedLeads && hasAbandonedLeads) {
      setSelectedTab("Abandoned");
    } else if (search) {
      setSelectedTab("All Leads");
    }
  }, [leads, search]);


  useEffect(() => {
    setLeads(allLeads ?? []);
    if (leadsError) {
      toast.error(leadsError?.response?.data?.message || leadsError?.message);
    }
  }, [allLeads, leadsError]);

  const [activeLead, setActiveLead] = useState(null);
  const [loadingLeads, setLoadingLeads] = useState(new Set());
  const [device, setDevice] = useState({ mobile: false, tab: false });
  const [showNotePopup, setShowNotePopup] = useState(false);
  useEffect(() => {
    if (typeof window !== "undefined")
      localStorage.setItem("filters", JSON.stringify(filters));
  }, [filters]);

  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.innerWidth <= 720;
      const isTab = window.innerWidth >= 720 && window.innerWidth <= 820;
      setDevice({ mobile: isMobile, tab: isTab });
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
    })
  );

  function onDragOver(event) {
    const { active, over } = event;
    if (!over) return;

    const activeId = active.id;
    const overId = over.id;

    if (activeId === overId) return;

    const isActiveALead = active.data.current?.type === "Lead";
    const isOverLead = over.data.current?.type === "Lead";

    if (!isActiveALead) return;

    if (isActiveALead && isOverLead) {
      setLeads((leads) => {
        const oldLeads = [...leads];
        const activeIndex = leads.findIndex((l) => l.email === activeId);
        const overIndex = leads.findIndex((l) => l.email === overId);

        if (leads[activeIndex].status !== leads[overIndex].status) {
          const previousStatus = oldLeads[activeIndex].status;

          leads[activeIndex].status = leads[overIndex].status;

          if (previousStatus !== leads[activeIndex].status) {
            updateStatus(leads[activeIndex]);
          }
          return arrayMove(leads, activeIndex, overIndex - 1);
        }
        return arrayMove(leads, activeIndex, overIndex);
      });
    }

    const isOverAColumn = over.data.current?.type === "Column";
    if (isActiveALead && isOverAColumn) {
      setLeads((leads) => {
        const oldLeads = [...leads];
        const activeIndex = leads.findIndex((l) => l.email === activeId);

        const previousStatus = oldLeads[activeIndex].status;

        leads[activeIndex].status = overId;

        if (previousStatus !== leads[activeIndex].status) {
          updateStatus(leads[activeIndex]);
        }
        return arrayMove(leads, activeIndex, activeIndex);
      });
    }
  }

  function onDragEnd(event) {
    setActiveLead(null);
    const { active, over } = event;

    if (!over) return;

    const activeId = active.id;
    const overId = over.id;


    if (activeId === overId) return;

    const isActiveALead = active.data.current?.type === "Lead";
    const isOverAColumn = over.data.current?.type === "Column";

    if (isActiveALead && isOverAColumn) {
      const activeLeadIndex = leads.findIndex((lead) => lead.email === activeId);

      if (activeLeadIndex !== -1) {
        const activeLead = leads[activeLeadIndex];

        if (activeLead.status !== overId) {

          setActiveLead(activeLead);
          setShowNotePopup(true);
        }
      } else {
        console.log("Active lead not found in leads array.");
      }
    } else {
      console.log("Drag operation not valid.");
    }
  }
  const downloadCSV = () => {
    const csvData = allLeads.map(lead => ({
      'Name': lead.fullName,
      'Email': lead.email,
      'Phone': lead.phone,
      'Status': lead.status,
      'Source': lead.source

    }));
    const csvString = Papa.unparse(csvData);
    const downloadLink = document.createElement('a');
    downloadLink.href = 'data:text/csv;charset=utf-8,' + encodeURI(csvString);
    downloadLink.target = '_blank';
    downloadLink.download = `all_leads.csv`; // Download filename
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };
  const isWithinLastMonth = (dateString) => {
    const today = new Date();
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(today.getMonth() - 1);

    const inputDate = new Date(dateString);
    return inputDate >= oneMonthAgo && inputDate <= today;
  };

  const updateStatus = (activeLead) => {
    const { email, status, source } = activeLead || {};
    setLoadingLeads(
      (prevLoadingLeads) => new Set([...prevLoadingLeads, email])
    );
    axios
      .put(
        "/crm",
        {
          email,
          status,
          source
        },
        { headers: { "x-api-key": process.env.REACT_APP_X_API_KEY_CRM || "" } }
      ).then(
        async () => await leadsMutate()
      )
      .catch((err) => {
        const error =
          err?.response?.data?.message || err?.response?.data?.error;
        if (error) toast.error(error);
        else toast.error("Something went wrong!");
      })
      .finally(() =>
        setLoadingLeads(
          (prevLoadingLeads) =>
            new Set([...prevLoadingLeads].filter((e) => e !== email))
        )
      );
  };

  const handleSync = async () => {
    const socket = io(`${apiGateway}/crm`);
    socket.on("connect", () => {


      socket.on("sync-update", (data) => {
        if (data?.progress) toast.success(data.progress);
      });
    });

    axios
      .post("/crm/sync", { source: filters.source }, {
        headers: { "x-api-key": process.env.REACT_APP_X_API_KEY_CRM || "" },
      })
      .then((res) => {
        if (res.data?.message) {
          toast.success("Syncing Successful");
        }
      })
      .catch((err) => {
        const error =
          err?.response?.data?.message || err?.response?.data?.error;
        if (error) toast.error(error);
        else toast.error("Something went wrong!");
      })
      .finally(() => {
        socket.disconnect();
        console.log("Socket connection disconnected");
      });
  };
  const [selectedTab, setSelectedTab] = useState("All Leads");
  const [selectedColumn, setSelectedColumn] = useState({ subscription: "Leads" });
  const handleButtonClick = (itemTitle) => {
    if (selectedColumn.subscription !== itemTitle) {
      setSelectedColumn({ subscription: itemTitle });
    }
  };
  const subscriptionOptions = [
    { name: "Standard", value: "standard" },
    { name: "Professional", value: "pro" },
    { name: "Enterprise", value: "enterprise" },
    { name: "Manager", value: "manager" },
  ];

  const handleFilterChange = (value) => {
    setFilters((prev) => ({
      ...prev,
      subscription: prev.subscription === value ? "" : value,
    }));
  };
  const tabs = ["All Leads", "Hosted", "Abandoned"];



  return (
    <SidebarLayout>

      <div className="px-3 md:px-6 lg:px-8 py-2 md:py-4 lg:py-6 overflow-x-auto no-scrollbar overflow-y-hidden">
        <div className="flex flex-row  items-center mt-3 mb-4  space-y-0">
          <h2 className="md:text-3xl text-xl font-medium leading-9 text-heading-1">
            Leads
          </h2>
          <div>

          </div>
          <div className="flex md:justify-between justify-end gap-2 w-full">
            <div className="lg:hidden ml-4 md:block">
              <DropDown
                selectedFilter={
                  subscriptionOptions.find(option => option.value === filters.subscription)?.name || "ALL"
                }
                options={subscriptionOptions.map(option => option.name)}
                onFilterChange={(name) => {
                  const selectedValue = subscriptionOptions.find(option => option.name === name)?.value || "";
                  handleFilterChange(selectedValue);
                }}
              />
            </div>

            <div className="lg:flex hidden flex-wrap ml-6 gap-3">
              {[
                { name: "Standard", value: "standard" },
                { name: "Professional", value: "pro" },
                { name: "Enterprise", value: "enterprise" },
                { name: "Manager", value: "manager" },
              ].map((item, index) => (
                <button
                  key={`${item.name}__${item.value}__${index}`}
                  onClick={() =>
                    setFilters((prev) => ({
                      ...prev,
                      subscription:
                        prev.subscription === item.value ? "" : item.value,
                    }))
                  }
                  className={`text-xs text-heading-2 border border-border  rounded-md px-2 py-1.5 bg-background-1 duration-200 ${item.value === filters.subscription
                    ? "bg-primary text-white"
                    : "border-border"
                    }`}
                >
                  {item.name}
                </button>
              ))}
              <Transition
                show={filters.subscription ? true : false}
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-x-1"
                enterTo="opacity-100 translate-x-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-x-0"
                leaveTo="opacity-0 translate-x-1"
              >
                <button
                  onClick={() =>
                    setFilters((prev) => ({ ...prev, subscription: "" }))
                  }
                  className="text-sm text-primary"
                >
                  Clear Filters
                </button>
              </Transition>
            </div>



            <div className="flex items-center gap-2">
              {/* Export Leads Button */}
              <Button className="px-1 py-2 hidden sm:flex" onClick={downloadCSV}>
                <FiDownload className="mr-2 h-5 w-5" />
                Export Leads
              </Button>
              <Button className="px-1 py-2 sm:hidden flex items-center gap-2" onClick={downloadCSV}>
                <FiDownload className="h-5 w-5" />
              </Button>


              <LeadUpload onUploadSuccess={leadsMutate} />


              <div className="relative">

                <div className="md:block hidden">
                  <FilterDropdownMenu
                    filters={filters}
                    setFilters={setFilters}
                    handleSourceChange={handleSourceChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex items-center justify-between">
          <div className="md:flex items-center gap-4 hidden ">
            {tabs.map((tab) => (
              <button
                key={tab}
                onClick={() => setSelectedTab(tab)}
                className={` ${selectedTab === tab ? " text-secondary-txt border-secondary-txt pb-1 border-b-2 " : "text-secondary-txt-1"
                  }`}
              >
                {tab}
              </button>
            ))}
          </div>
          <div className="flex items-center w-full mb-5  md:ml-4 border border-border rounded-lg md:w-[30%]  relative ">
            <label htmlFor="search" className="absolute  left-2">
              <IoSearchOutline className="h-5 w-5 text-heading-1" />
            </label>
            <input
              name="search"
              type="search"
              placeholder="Search"
              defaultValue={search || ""}
              className="md:ml-6 ml-8 text-sm flex-1 bg-transparent w-full text-placeholder md:px-3 md:py-2 py-1.5 placeholder-placeholder sm:text-sm  focus:outline-none focus:border-primary focus:ring-primary"
              onChange={e => debouncedSetSearch(e.target.value)}
            />
          </div>
        </div>
        <div className="flex md:hidden gap-2  ">
          {[
            { name: "Leads", title: "Leads" },
            { name: "QUALIFIED", title: "Qualified" },
            { name: "ENGAGED", title: "Discovery" },
            { name: "SALE", title: "Hosted" },
            { name: "ABANDONED", title: "Abandoned" },
          ].map((item, index) => (
            <button
              disabled={item.title === filters.subscription}
              key={`${item.name}__${item.value}__${index}`}
              onClick={() => handleButtonClick(item.title)}
              className={`text-xs text-heading-2 px-1 py-1.5 duration-200 ease-in-out transform ${item.title === selectedColumn.subscription
                ? "border-b-2 border-background-3 text-secondary-txt scale-105"
                : "text-secondary-focus"
                } transition-all`}

            >
              {item.name}
            </button>
          ))}

        </div>

        <div className="flex items-center mt-4">
          <DndContext
            sensors={sensors}
            onDragEnd={onDragEnd}
            onDragOver={onDragOver}
            onDragStart={(e) => setActiveLead(e.active.data.current.lead)}
          >
            {/* Board Columns */}
            {!allLeads && !leadsError ? (
              <Skeleton type="board" />
            ) : device.mobile || device.tab ? (

              <Swiper
                slidesPerView={device.tab ? 2 : 1}
                spaceBetween={device.tab ? 20 : 0}
                pagination={{ clickable: true }}
                modules={[Pagination]}
              >
                <SwiperSlide key="col.id">
                  {boardColumns
                    .filter((col) => col.title === selectedColumn.subscription)
                    .map((col) => (
                      <Column
                        selectedTab={selectedTab}
                        key={col.id}
                        column={col}
                        leads={leads.filter((lead) => lead.status === col.id)}
                        loadingLeads={loadingLeads}
                        leadsMutate={leadsMutate}
                        source={filters.source}

                      />
                    ))}
                </SwiperSlide>
              </Swiper>
            ) : (
              <div className={`grid lg:grid-cols-4 md:grid-cols-2 gap-4  w-full`}>
                {boardColumns
                  .filter((col) => {
                    if (selectedTab === "All Leads") return col.id !== "ABANDONED";
                    if (selectedTab === "Hosted") return col.id === "SALE";
                    if (selectedTab === "Abandoned") return col.id === "ABANDONED";
                    return true;
                  })
                  .map((col) => (
                    <Column
                      selectedTab={selectedTab}
                      key={col.id}
                      column={col}
                      source={filters.source}
                      leads={leads.filter(
                        (lead) =>
                          lead.status === col.id &&
                          ((selectedTab === "All Leads" && col.id === "SALE")
                            ? isWithinLastMonth(lead.updated_at) && !lead?.hideCard
                            : true)
                      )}
                      loadingLeads={loadingLeads}
                      leadsMutate={leadsMutate}
                      isWithinLastMonth={isWithinLastMonth}
                    />
                  ))}
                {createPortal(
                  <DragOverlay>
                    {activeLead && (
                      <LeadCard
                        lead={activeLead}
                        loadingLeads={loadingLeads}
                        leadsMutate={leadsMutate}

                      />
                    )}

                  </DragOverlay>,
                  document.body
                )}

              </div>
            )}
          </DndContext>
        </div>

      </div>
    </SidebarLayout>
  );
}

export default Sales;

