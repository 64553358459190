import React from "react";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import c from "classnames";

const Pagination = ({
  totalPages,
  currentPage,
  setCurrentPage,
  hideButtonPages,
  className,
}) => {
  const onNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const onPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const getPageNumbers = () => {
    const numbers = [];
    const maxVisiblePages = 5;

    if (totalPages <= maxVisiblePages) {
      for (let i = 1; i <= totalPages; i++) {
        numbers.push(
          <button
            key={i}
            onClick={() => setCurrentPage(i)}
            className={`px-4 py-1.5 border dark:border-white rounded-lg hover:bg-background-4 hover:text-label duration-200 mx-1 ${
              i === currentPage
                ? "bg-background-3 dark:text-label text-white cursor-default"
                : "dark:bg-transparent text-secondary-txt-1"
            }`}
          >
            {i}
          </button>
        );
      }
    } else {
      let start = currentPage - Math.floor(maxVisiblePages / 2);
      let end = currentPage + Math.floor(maxVisiblePages / 2);

      if (start < 1) {
        end += Math.abs(start) + 1;
        start = 1;
      }
      if (end > totalPages) {
        start -= end - totalPages;
        end = totalPages;
      }

      for (let i = 1; i <= totalPages; i++) {
        if (i === 1 || i === totalPages || (i >= start && i <= end)) {
          numbers.push(
            <button
              key={i}
              onClick={() => setCurrentPage(i)}
              className={`px-4 py-1.5 rounded-lg border-border  hover:bg-background-4 hover:text-label  duration-200 mx-1 ${
                i === currentPage
                  ? "bg-background-3 text-label cursor-default"
                  : "dark:bg-transparent text-secondary-txt-1"
              }`}
            >
              {i}
            </button>
          );
        } else if (i === start - 1 || i === end + 1) {
          numbers.push(
            <div key={i} className="flex space-x-1">
              <p className="w-1 h-1 mx-auto rounded-full bg-background-3"></p>
              <p className="w-1 h-1 mx-auto rounded-full bg-background-3"></p>
              <p className="w-1 h-1 mx-auto rounded-full bg-background-3"></p>
            </div>
          );
        }
      }
    }

    return numbers;
  };

  return (
    <div
      className={c(
        "flex items-center justify-center md:justify-between",
        className
      )}
    >
      {hideButtonPages || (
        <span className="hidden md:inline text-sm font-medium leading-5 shrink-0 text-description-1">
           showing: {currentPage < 10 ? `0${currentPage}` : currentPage} of {totalPages < 10 ? `0${totalPages}` : totalPages}
        </span>
      )}
      <div
        className={`flex items-center justify-end ${
          hideButtonPages ? "" : "w-full"
        }`}
      >
        <button
          onClick={onPreviousPage}
          disabled={currentPage === 1}
          className={`rounded-lg border border-border p-2 ${
            currentPage === 1 ? "cursor-not-allowed bg-background-4" : ""
          }`}
        >
         
         <IoIosArrowBack className="w-5 h-5"  />
          
        </button>
        {!hideButtonPages && (
          <div className="hidden lg:flex items-center justify-center">
            {getPageNumbers()}
          </div>
        )}
        <button
          onClick={onNextPage}
          disabled={currentPage === totalPages || !totalPages}
          className={`rounded-lg border border-border p-2 ${
            currentPage === totalPages ? "cursor-not-allowed bg-background-4" : ""
          }`}
        >
          <IoIosArrowForward className="w-5 h-5" />
          
        </button>
      </div>
    </div>
  );
};

export default Pagination;
