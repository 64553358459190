import React, { useEffect, useRef, useState, Fragment } from "react";
import MarketingLayout from "../../layouts/MarketingLayout";
import Button from "../../components/Button";
import { HiDotsHorizontal, HiOutlinePlus } from "react-icons/hi";
import DataTable from "../../components/DataTable";
import Pagination from "../../components/Pagination";
import useSWR from "swr";
import axios from "axios";
import { marketingApi } from "../../config";
import { stripeRabbitoProdcuts } from "../../constants/stripeProducts";
import { Dialog, Menu, Popover, Switch, Transition } from "@headlessui/react";
import { FiX } from "react-icons/fi";
import { ImPencil2 } from "react-icons/im";
import CheckBox from "../../components/CheckBox";
import toast from "react-hot-toast";
import { areObjectsEqual } from "../../utils/common";
import Loading from "../../components/Loading";
import { FaAngleDown } from "react-icons/fa6";
import CardList from "../../components/CardList";
import Skeleton from "../../components/Skeleton";

const PROMOTION_LIMIT = 10;

const Promotions = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [openPromotion, setOpenPromotion] = useState(false);
  const [cursorsHistory, setCursorsHistory] = useState([]);
  const [selectedToEdit, setSelectedToEdit] = useState(null);
  const [pageIndex, setPageIndex] = useState(1);
  const prevPageIndexRef = useRef(pageIndex);
  const [formVal, setFormVal] = useState({
    isActive: false,
  });

  const {
    data: { promotions, limit, total, nextCursor } = {},
    error: promotionsError,
    mutate: promotionMutate,
  } = useSWR([cursorsHistory, PROMOTION_LIMIT], async () => {
    return axios
      .post(
        `${marketingApi}/promotion?limit=${PROMOTION_LIMIT}${cursorsHistory.length > 0
          ? `&cursor=${cursorsHistory[cursorsHistory.length - 1]}`
          : ""
        }`,
        {}
      )
      .then((response) => response.data);
  });


  useEffect(() => {
    if (pageIndex > prevPageIndexRef.current) {
      setCursorsHistory((prevHistory) => [...prevHistory, nextCursor]);
    } else if (pageIndex < prevPageIndexRef.current) {
      const newHistory = cursorsHistory.slice(0, -1);
      setCursorsHistory(newHistory);
    }
    prevPageIndexRef.current = pageIndex;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageIndex]);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const columns = [
    {
      key: "promotion",
      name: (
        <p className="font-medium text-base capitalize px-12  leading-5 text-tableheader">
          Promotion
        </p>),
      render: (row) => (
        <div>
          <p className="text-base  leading-5 px-12 text-tablebody">
            {row?.id}
          </p>
          {(() => {
            if (!isMobile) return;
            return (
              <div>
                <div className="flex items-center text-xs">
                  <p className="font-medium text-base leading-5 capitalize  text-tableheader">
                    {row?.percentage}% OFF
                  </p>

                  <p
                    className={`text-sm  leading-5  px-2 py-0.5 rounded-full ${row?.isActive
                      ? "bg-green-light text-green-light-txt"
                      : "bg-red-light text-red-light-txt"
                      }`}
                  >
                    {row?.isActive ? "Active" : "Inactive"}
                  </p>
                </div>
                <div className="flex flex-wrap">
                  {row?.product?.map((i, index) => {
                    const stripeProduct = stripeRabbitoProdcuts.find(
                      (p) => p.id === i
                    );

                    return (
                      <p
                        key={`${i}__${index}`}
                        className="text-base  leading-5 text-tablebody"
                      >
                        {stripeProduct ? stripeProduct.name : ""}
                        {stripeProduct &&
                          row?.product?.length - 1 !== index &&
                          ", "}
                      </p>
                    );
                  })}
                </div>
              </div>
            );
          })()}
        </div>
      ),
    },

    {
      key: "actions",
      name: (
        <p className=" font-medium capitalize  text-base leading-5 text-tableheader">
          Actions
        </p>),
      render: (row) => (
        <Menu as="div" className="absolute top-4">
          <Menu.Button className="inline-flex w-full z-10  justify-center rounded opacity-90 hover:opacity-100 px-1 font-medium focus:outline-none">
            <HiDotsHorizontal
              className="h-5 w-5 ml-4 text-heading-1"
              aria-hidden="true"
            />
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-1 z-50 w-28 divide-y divide-border-border-1 rounded-md bg-background-4 shadow-lg focus:outline-none border border-border-1">
              <div className="px-1 py-2">
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={() => {
                        setSelectedToEdit(row);
                        setOpenPromotion(true);
                      }}
                      className={`${active ? "bg-background-3 text-white text-md" : "text-label text-md"
                        } group flex gap-1 w-full items-center rounded-md px-2 py-2 text-sm`}
                    >
                      <span>Edit</span>
                    </button>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      onClick={() => {
                        setFormVal((prev) => ({ ...prev, isActive: !prev.isActive }));
                        handlePromotionStatus(row.id, !row.isActive); // Toggle the isActive status
                      }}
                      className={`${active ? "bg-background-3 text-white text-md" : "text-label text-md"
                        } group flex gap-1 w-full items-center rounded-md px-2 py-2 text-sm`}
                    >
                      <span>{row.isActive ? "Inactive" : "Active"}</span>
                    </button>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Menu>

      )
    }

  ];

  if (!isMobile) {
    const itemsToInsert = [
      {
        key: "offPercentage",
        name: (
          <p className="font-medium text-base capitalize text-tableheader">
            % Off
          </p>
        ),
        render: (row) => (
          <p className="text-base  text-tablebody px-1">
            {row?.percentage}%
          </p>
        ),
      },
      {
        key: "status",
        name: (
          <p className="font-medium text-base capitalize  leading-5 text-tableheader">
            Status
          </p>
        ),
        render: (row) => (
          <p
            className={`text-sm  leading-5 px-4 py-1 rounded-full ${row?.isActive
              ? "bg-background-active text-green-light-txt "
              : "bg-background-inactive text-red-light-txt"
              }`}
          >
            {row?.isActive ? "Active" : "Inactive"}
          </p>
        ),
      },
      {
        key: "products",
        name: (
          <p h2 className="font-medium text-base capitalize  leading-5 text-tableheader">
            Products
          </p>
        ),
        render: (row) => (
          <div className="flex flex-nowrap md:flex-wrap">
            {row?.product?.map((i, index) => {
              const stripeProduct = stripeRabbitoProdcuts.find(
                (p) => p.id === i
              );

              return (
                <p
                  key={`${i}__${index}`}
                  className="text-base  leading-5 text-tablebody"
                >
                  {stripeProduct ? stripeProduct.name : ""}
                  {stripeProduct && row?.product?.length - 1 !== index && ", "}
                </p>
              );
            })}
          </div>
        ),
      },
    ];
    columns.splice(1, 0, ...itemsToInsert);
  }
  const handlePromotionStatus = async (promotionId, isActive) => {
    try {
      await axios.patch(`${marketingApi}/promotion/${promotionId}`, { isActive });
      await promotionMutate();
      const message = `Promotion status updated successfully!`;
      toast.success(message);
    } catch (error) {
      console.error('Error updating promotion status:', error.message);
    } finally {

    }
  };


  return (
    <MarketingLayout openPromotionHandler={() => setOpenPromotion(true)}>
      <div className="mt-8 px-o md:hidden block">
        {!promotions && !promotionsError ? (<Skeleton type={"CardList"} />) :
          (<CardList>
            {promotions?.map((row) => (
              <div key={row.email} className="border border-border rounded-lg p-4 m-3 shadow-sm">
                <div className="flex items-center justify-between relative pb-2">
                  <p className="text-tablebody">
                    <span className='text-tableheader font-semibold'>Promotion: </span> {row.id}
                  </p>
                  <p className="text-tablebody">
                    <span className='text-tableheader font-semibold'>%Off: </span>   {row?.percentage}%
                  </p>
                  <Menu as="div" className="realtive ">
                    <Menu.Button className="inline-flex w-full z-10  justify-center rounded opacity-90 hover:opacity-100 px-1 font-medium focus:outline-none">
                      <HiDotsHorizontal
                        className="h-5 w-5 ml-4 text-heading-1"
                        aria-hidden="true"
                      />
                    </Menu.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute top-4 right-2 z-50 w-28 divide-y divide-border-border-1 rounded-md bg-background-4 shadow-lg focus:outline-none border border-border-1">
                        <div className="px-1 py-2">
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                onClick={() => {
                                  setSelectedToEdit(row);
                                  setOpenPromotion(true);
                                }}
                                className={`${active ? "bg-background-3 text-white text-md" : "text-label text-md"
                                  } group flex gap-1 w-full items-center rounded-md px-2 py-2 text-sm`}
                              >
                                <span>Edit</span>
                              </button>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <button
                                onClick={() => {
                                  setFormVal((prev) => ({ ...prev, isActive: !prev.isActive }));
                                  handlePromotionStatus(row.id, !row.isActive);
                                }}
                                className={`${active ? "bg-background-3 text-white text-md" : "text-label text-md"
                                  } group flex gap-1 w-full items-center rounded-md px-2 py-2 text-sm`}
                              >
                                <span>{row.isActive ? "Inactive" : "Active"}</span>
                              </button>
                            )}
                          </Menu.Item>
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>


                </div>
                <div className="flex flex-col gap-2">
                  <p className="text-tablebody">
                    <span className='text-tableheader font-semibold'>status: </span> <span
                      className={`text-sm  leading-5 px-4 py-1 rounded-full ${row?.isActive
                        ? "bg-background-active text-green-light-txt "
                        : "bg-background-inactive text-red-light-txt"
                        }`}
                    >
                      {row?.isActive ? "Active" : "Inactive"}
                    </span>
                  </p>
                  <p className="text-tablebody flex items-center">
                    <span className='text-tableheader font-semibold'>Product: </span>
                    {row?.product?.map((i, index) => {
                      const stripeProduct = stripeRabbitoProdcuts.find(
                        (p) => p.id === i
                      );

                      return (
                        <p
                          key={`${i}__${index}`}
                          className="text-base ml-2 truncate leading-5 text-tablebody"
                        >
                          {stripeProduct ? stripeProduct.name : ""}
                          {stripeProduct && row?.product?.length - 1 !== index && ", "}
                        </p>
                      );
                    })}
                  </p>

                </div>
              </div>
            ))}
          </CardList>)}
      </div>
      <div className="mt-8 px-0 md:px-2 hidden md:block lg:px-0">
        <DataTable
          data={promotions}
          columns={columns}
          loading={!promotions && !promotionsError}
          actionLoader={{ loadingSet: new Set(), loadingField: "" }}
        />
      </div>

      {Math.ceil(total / limit) > 1 && (
        <Pagination
          className="bg-transparent p-3 rounded-b-md md:block hidden mt-2.5"
          totalPages={Math.ceil(total / limit)}
          currentPage={pageIndex}
          setCurrentPage={setPageIndex}
        />
      )}


      {openPromotion && (
        <PromotionManagement
          isOpen={openPromotion}
          mutate={promotionMutate}
          selectedToEdit={selectedToEdit}
          close={() => {
            setOpenPromotion(false);
            selectedToEdit && setSelectedToEdit(null);
          }}
        />
      )}
    </MarketingLayout>
  );
};

export default Promotions;

const PromotionManagement = ({ isOpen, close, mutate, selectedToEdit, }) => {
  const { product, id, isActive, percentage } = selectedToEdit || {};
  const [formVal, setFormVal] = useState(
    selectedToEdit
      ? {
        products: product ?? [],
        promotionCode: id ?? "",
        isActive: isActive ?? false,
        percentage: percentage?.toString() ?? "",
      }
      : { promotionCode: "", isActive: false, products: [], percentage: "" }
  );
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [isDisabled, setDisabled] = useState(false);

  useEffect(() => {
    if (!selectedToEdit) return;
    const response = areObjectsEqual(formVal, {
      products: product ?? [],
      promotionCode: id ?? "",
      isActive: isActive ?? false,
      percentage: percentage?.toString() ?? "",
    });
    setDisabled(response);
  }, [selectedToEdit, formVal, product, id, isActive, percentage]);

  const handleOnChange = (e) => {
    const { name, value } = e.target || {};
    setFormVal((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { percentage, products, promotionCode } = formVal || {};

    if (!promotionCode || promotionCode?.trim() === "")
      return setError("Please provide a valid promotion code ");
    else if (!products?.length)
      return setError("Please select at least one product.");

    let body = {
      ...formVal,
      percentage: Number(percentage),
      product: products,
    };
    delete body[promotionCode];

    error && setError("");
    setLoading(true);

    const config = {
      method: selectedToEdit ? "PATCH" : "POST",
      url: `${marketingApi}/promotion/${promotionCode?.trim()}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: body,
    };

    axios
      .request(config)
      .then(async (res) => {
        if (res.data?.success) {
          close();
          setFormVal((prev) => ({ ...prev, products: [] }));
          await mutate();
          const message = `Promo code '${res?.data?.promoCode
            }' has been successfully ${selectedToEdit ? "updated" : "created"}!`;
          toast.success(message);
        }
      })
      .catch((err) => {
        const error =
          err?.response?.data?.message || err?.response?.data?.error;
        setError(error);
      })
      .finally(() => setLoading(false));
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0  z-50 overflow-y-auto"
        onClose={() => {
          close();
          error && setError("");
          setFormVal((prev) => ({ ...prev, products: [] }));
        }}
      >
        <div className="relative min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block md:w-[25%] max-w-xl p-7 shadow-[0_1px_10px_3px_rgba(111,84,198,0.7)]  overflow-visible text-left align-middle transition-all transform bg-background-2 rounded-lg">
              <Dialog.Title
                as="h3"
                className="text-lg font-semibold leading-7 text-heading-1"
              >
                {selectedToEdit ? "Edit Promotion" : "Add Promotion"}
              </Dialog.Title>
              <button onClick={close}>
                <FiX className="h-5 w-5 text-heading-1  rounded-full outline-none absolute top-7 right-7" />
              </button>

              <form
                className="flex flex-col gap-3 w-full mx-auto "
                onSubmit={handleSubmit}
              >
                <div className=" gap-3 text-label">
                  <label
                    htmlFor="promotionCode"
                    className="block text-sm font-medium mb-2 min-w-[180px]"
                  >
                    Promotion Code
                  </label>

                  <input
                    required
                    name="promotionCode"
                    type="text"
                    placeholder="RAB50"
                    value={formVal.promotionCode ?? ""}
                    disabled={selectedToEdit}
                    className="appearance-none  shadow-sm block w-full px-3 py-2 border border-border rounded-md placeholder-gray-400 sm:text-sm focus:z-10 focus:outline-none focus:border-primary focus:ring-primary"
                    onChange={handleOnChange}
                  />

                </div>
                <div className=" text-label">
                  <label
                    htmlFor="percentage"
                    className="block text-sm mb-2 font-medium min-w-[180px]"
                  >
                    Percentage off
                  </label>
                  <input
                    required
                    min="0"
                    max="100"
                    name="percentage"
                    type="number"
                    placeholder="50%"
                    value={formVal.percentage ?? ""}
                    className="appearance-none bg-background-input shadow-sm block w-full px-3 py-2 border border-border rounded-md placeholder-gray-400 sm:text-sm focus:z-10 focus:outline-none focus:border-primary focus:ring-primary"
                    onChange={handleOnChange}
                  />
                </div>
                <div className="  text-label">
                  <label
                    htmlFor="product"
                    className="block text-sm font-medium mb-2 min-w-[180px]"
                  >
                    Product
                  </label>
                  <Popover className="relative w-full">
                    {({ open }) => (
                      <>
                        <Popover.Button
                          className={`w-full flex flex-col gap-2 space-x-2 outline-none ${open ? "" : "text-opacity-90"
                            }`}
                        >
                          <input
                            required
                            name="product"
                            type="text"
                            rows={2}
                            value={formVal.products
                              .map((productId) => {
                                const product = stripeRabbitoProdcuts.find(
                                  (p) => p.id === productId
                                );
                                return product ? product.name : "";
                              })
                              .join(", ")}
                            className="appearance-none bg-background-input shadow-sm block w-full px-3 py-2 border border-border rounded-md placeholder-gray-400 sm:text-sm focus:z-10 focus:outline-none focus:border-primary focus:ring-primary"
                            onChange={handleOnChange}
                          />
                          <span className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                            <FaAngleDown className="h-5 w-5 text-heading-2" />
                          </span>
                        </Popover.Button>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-200"
                          enterFrom="opacity-0 translate-y-1"
                          enterTo="opacity-100 translate-y-0"
                          leave="transition ease-in duration-150"
                          leaveFrom="opacity-100 translate-y-0"
                          leaveTo="opacity-0 translate-y-1"
                        >
                          <Popover.Panel className=" left-0 z-50 w-50">
                            <div className="overflow-visible text-left align-middle transition-all transform bg-background-1 shadow-xl rounded-lg ">
                              <div className="p-3">
                                {stripeRabbitoProdcuts.map((product) => (
                                  <CheckBox

                                    hasBottomBorder={false}
                                    key={product.id}
                                    name={product.name}
                                    id={product.id}
                                    onClick={(e) =>
                                      setFormVal((prev) => {
                                        if (prev.products.includes(e.id)) {
                                          return {
                                            ...prev,
                                            products: prev.products.filter(
                                              (product) => product !== e.id
                                            ),
                                          };
                                        } else {
                                          return {
                                            ...prev,
                                            products: [...prev.products, e.id],
                                          };
                                        }
                                      })
                                    }
                                    checked={formVal.products.includes(
                                      product.id
                                    )}

                                  />
                                ))}
                              </div>
                            </div>
                          </Popover.Panel>
                        </Transition>
                      </>
                    )}
                  </Popover>
                </div>
                {error && (
                  <span className="text-xs text-error-red block mt-4 -mb-4 text-right">
                    {error}
                  </span>
                )}
                <div className="mt-4 w-full flex justify-end">
                  <Button className="w-full flex justify-center" disabled={loading || isDisabled || !formVal.products.length || !formVal.promotionCode || !formVal.percentage} loading={loading} type="submit">
                    {" "}
                    {selectedToEdit ? "Update" : "Add"}
                  </Button>
                </div>
              </form>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

/*
|| TODO: If not being used delete 

  const handlePromotionStatus = async (promotionId, isActive) => {
    setLoading((prevLoading) => new Set([...prevLoading, promotionId]));
    axios.patch(`${apiGateway}/promotion/${promotionId}`, {}).then(() => {});
  };

  <Switch
            disabled={loadingPromotions.has(row?.id)}
            title={row?.isActive ? "Active" : "Inactive"}
            checked={row?.isActive}
            onChange={handlePromotionStatus}
            className={`${
              row?.isActive ? "bg-primary" : "bg-background-3"
            } relative inline-flex flex-shrink-0 h-5 w-10 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-primary'`}
          >
            <div
              className={`${
                row?.isActive ? "translate-x-5" : "translate-x-0"
              } pointer-events-none relative inline-block h-4 w-4 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`}
            ></div>
          </Switch>

*/
