import { getAuth, onAuthStateChanged, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { initializeFirebaseApp } from '../providers/firebase';
import { useEffect, useState } from "react";

const useAuth = () => {

    const [loading, setLoading] = useState(true)
    const [error, setError] = useState('')
    const [user, setUser] = useState(null);
    initializeFirebaseApp();
    const auth = getAuth();

    useEffect(() => {
        setLoading(true);
          onAuthStateChanged(auth, (user) => {
            if (user) {
              setUser(user);
            } else {
              setUser(null);
            }
            setLoading(false);
        });
    }, [auth]);

    // signin user
    const signIn = (email, password) => {
        setLoading(true);
        setError('');
        return signInWithEmailAndPassword(auth, email, password)
    }

    //   logout 
      const logout = () => {
        signOut(auth).then(() => {
            console.log('logged out');
          })
      }
    
    return {
        user, signIn, loading, error, setError, setUser, logout
    }

};

export default useAuth;