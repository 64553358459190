import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useState } from "react";
import { FiX } from "react-icons/fi";
import Checkbox from "../RadioButton";
import Button from "../Button";
import axios from "axios";
import { toast } from "react-hot-toast";
import useAuth from "../../hooks/useAuth";
import useSWR, { useSWRConfig } from "swr";
import Skeleton from "../Skeleton";

const SendEmail = ({ isOpen, close, lead }) => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState({});

  // converts string to proper case
  function toProperCase(str) {
    let upper = true
    let newStr = ""
    for (let i = 0, l = str.length; i < l; i++) {
        // Note that you can also check for all kinds of spaces  with
        // str[i].match(/\s/)
        if (str[i] == " ") {
            upper = true
            newStr += str[i]
            continue
        }
        newStr += upper ? str[i].toUpperCase() : str[i].toLowerCase()
        upper = false
    }
    return newStr
  }

  // eslint-disable-next-line no-unused-vars
  const [source, setSource] = useState(() => {
    const storedFilters =
      typeof window !== "undefined" && localStorage.getItem("filters");
    return storedFilters
      ? JSON.parse(storedFilters)?.source
      : { source: "rabbito" };
  });

  const { data: { data: emailTemplates } = {}, error: templatesError } = useSWR(
    `crm/templates/${source}`
  );

  const { user } = useAuth();
  const { mutate } = useSWRConfig();

  const sendEmail = async () => {
    if (!lead?.email) return setError("Email not found");

    const { id, name } = selectedTemplate || {};

    /*
      Put together email template data
      ie firstName to be used in emails
    */
    let templateData = {};
    let firstName = lead.fullName;
    let lastName = "";
    let userId = "";
    if (lead.fullName) {
      try {
        firstName = lead.fullName?.split(" ")[0];
        lastName = lead.fullName?.split(" ")[1];
        userId = lead.userId; // for users who already signed up
        templateData.firstName = toProperCase(firstName);
        templateData.lastName = toProperCase(lastName);
        templateData.userId = userId;
      } catch (e) {
        console.log(e);
        console.log(`Using the fullname : ${lead.fullName}`);
        firstName = lead.fullName;
        lastName = "";
      } finally {
        console.log(`Name of the lead is: ${templateData.firstName} ${templateData.lastName}`)
      }

    }
   


    if (!id) return setError("Select a template from the list to send email!");

    setLoading(true);
    axios
      .post("/notification", {
        to: [lead?.email],
        fromEmail: "mazhar@rabbito.social",
        templateId: id,
        templateData,
        type: "template",
        subject: name ?? "Welcome to Rabbito Social",
      })
      .then(async (res) => {
        if (res.data?.success_emails?.length) {
          await saveNote(name);
          toast.success("Successfully sent mail to the recipient!");
        }
      })
      .catch((err) => {
        const error =
          err?.response?.data?.message || err?.response?.data?.error;
        if (error) setError(error);
        else setError("Something went wrong!");
      })
      .finally(() => setLoading(false));
    close();
  };

  const saveNote = async (name) => {
    const body = {
      email: lead?.email,
      source: lead?.source,
      type: "note",
      note: `The ${name} email has been sent to the user.`,
    };

    body["created_by"] = user.email ?? "";
    return axios
      .post("/crm", body, {
        headers: { "x-api-key": process.env.REACT_APP_X_API_KEY_CRM || "" },
      })
      .then(async () => {
        mutate(`/crm/${lead?.email}`);
        return { success: true };
      })
      .catch((err) => {
        const error =
          err?.response?.data?.message || err?.response?.data?.error;
        if (error) toast.error(error);
        else toast.error("Something went wrong, while saving the note.");
        return { success: false };
      });
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-y-auto"
        onClose={close}
      >
        <div className="relative min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-lg max-h-400 overflow-hidden text-left align-middle transition-all transform bg-background-4 shadow-[0_1px_10px_3px_rgba(111,84,198,0.7)]	 rounded-[20px] my-8">
              <div className="flex items-center space-x-4 p-6">
                <Dialog.Title
                  as="h3"
                  className="text-lg font-semibold leading-7 text-heading-1"
                >
                  Recipient
                </Dialog.Title>
                <input
                  title={lead?.email}
                  type="email"
                  defaultValue={lead?.email}
                  disabled
                  className="text-sm text-description border border-border-1 rounded-md px-2 py-1 truncate"
                />
                <button onClick={close}>
                  <FiX className="h-5 w-5 text-red-light-txt bg-background-5 rounded-full  absolute top-7 right-7" />
                </button>
              </div>

              <hr className="bg-background-1 border-0 h-px w-[95%] mx-auto my-2" />
              <div className="p-6 h-[50vh] overflow-y-auto relative">
                <h3 className="text-base font-semibold leading-7 text-heading-1 mb-4">
                  Select email template from list below{" "}
                </h3>
                {!emailTemplates && !templatesError ? (
                  <Skeleton type={"sendEmail"} />
                ) : emailTemplates?.length ? (
                  emailTemplates.map((template) => (
                    <Checkbox
                      key={template.id}
                      name={template.name}
                      id={template.id}
                      onDoubleClick={() => sendEmail()}
                      onClick={() =>
                        setSelectedTemplate({
                          ...template,
                          templateData: {
                            ...template.templateData,
                            email: lead?.email,
                          },
                        })
                      }
                      checked={selectedTemplate.id === template.id}
                    />
                  ))
                ) : (
                  ""
                )}
                {(error || templatesError) && (
                  <span className="text-xs text-error-red mt-4 inline-block">
                    {error ||
                      templatesError?.response?.data?.message ||
                      templatesError?.response?.data?.error}
                  </span>
                )}
              </div>
              <div className="absolute bottom-4 left-0 right-0 text-center">
                <Button
                  disabled={!selectedTemplate?.id}
                  onClick={sendEmail}
                  loading={loading}
                  className="flex justify-center items-center py-2  w-11/12"
                >
                  Send Email
                </Button>

              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default SendEmail;
