import { BsCheckLg } from "react-icons/bs";

export default function RadioButton({ name, id, onClick, checked }) {
  return (
    <div className="flex items-center space-x-2 border-b border-border w-full">
      <div className="relative flex items-center">
        <input
          type="radio"
          name="radio"
          className="before:content[''] peer relative h-4 w-4 cursor-pointer appearance-none rounded border border-border bg-transparent transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:border-border before:opacity-0 before:transition-opacity checked:border-primary hover:before:opacity-10"
          id={id}
          value={id}
          checked={checked}
          onChange={() => onClick({ name, id })}
        />
        <div className="pointer-events-none absolute top-0 left-0 text-primary opacity-0 transition-opacity peer-checked:opacity-100 flex items-center justify-center">
          <BsCheckLg className="w-4 h-4" />
        </div>
      </div>
      <label
        className="flex items-center rounded cursor-pointer leading-6 font-medium text-sm text-label my-4"
        htmlFor={id}
        data-ripple-dark="true"
      >
        {name}
      </label>
    </div>
  );
}
