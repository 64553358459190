
import React, { useRef } from "react";
import axios from "axios";
import { toast } from "react-hot-toast";

import { FiUpload } from "react-icons/fi";
import "../../styles/swiper.css";
import Papa from 'papaparse';
const LeadUpload = ({ onUploadSuccess }) => {
    const fileInputRef = useRef(null);

    const handleFileUpload = async (e) => {
        const file = e.target.files[0];
        fileInputRef.current.value = '';

        if (!file) {
            return;
        }

        if (!file.name.endsWith('.csv')) {
            toast.error('Please upload a CSV file.');
            return;
        }

        try {
            const result = await parseCSV(file);
            const leadsData = result.data.map((row) => ({
                name: row.name || '',
                email: row.Email || '',
                phone: row.Phone || '',
                status: row.Status || 'NEW',
                source: row.Source || 'rabbito'
            }));

            for (let lead of leadsData) {
                await importLead(lead);
            }

            onUploadSuccess(); // Notify parent component about successful upload
        } catch (error) {
            console.error('Error handling CSV upload:', error);
            toast.error('Error parsing CSV file. Please check the file format.');
        }
    };

    const parseCSV = (file) => {
        return new Promise((resolve, reject) => {
            Papa.parse(file, {
                header: true,
                skipEmptyLines: true,
                complete: resolve,
                error: reject,
            });
        });
    };

    const importLead = async (lead) => {
        try {
            const response = await axios.post(
                '/crm',
                { ...lead },
                { headers: { 'x-api-key': process.env.REACT_APP_X_API_KEY_CRM || '' } }
            );

            if (response.status === 200) {
                toast.success(`Lead ${lead.email} Imported successfully.`);
                await verifyLeadEmail(lead.email, lead.source);
            } else {
                toast.error(`Error importing lead ${lead.email}.`);
            }
        } catch (error) {
            toast.error(`Error importing lead ${lead.email}.`);
        }
    };

    const verifyLeadEmail = async (email, source) => {
        try {
            const verifyResponse = await axios.post(
                '/crm/verify',
                { email, source },
                { headers: { 'x-api-key': process.env.REACT_APP_X_API_KEY_CRM || '' } }
            );

            if (verifyResponse.status === 200) {
                toast.success(`Lead ${email} verified successfully.`);
            } else {
                toast.warn(`Lead ${email} imported, but email verification failed: ${verifyResponse.data.message}`);
            }
        } catch (verifyError) {
            toast.error(`Error verifying email for lead ${email}: ${verifyError.response ? verifyError.response.data.message : verifyError.message}`);
        }
    };

    return (
        <div>
            <input
                type="file"
                id="csv-upload"
                accept=".csv"
                className="hidden"
                ref={fileInputRef}
                onChange={handleFileUpload}
            />
            <label htmlFor="csv-upload" className="bg-primary text-white px-2.5 py-[7px] rounded-md whitespace-nowrap items-center gap-2 cursor-pointer hidden sm:flex">
                <FiUpload className="h-5 w-5" />
                Import Leads
            </label>
            <label htmlFor="csv-upload" className="bg-primary text-white px-4 py-[9px] rounded-md whitespace-nowrap flex items-center gap-2 cursor-pointer sm:hidden">
                <FiUpload className="h-5 w-5" />

            </label>
        </div>
    );
};
export default LeadUpload;

//   <Button className="px-1 py-2 hidden sm:flex" onClick={downloadCSV}>
//                 <FiDownload className="mr-2 h-5 w-5" />
//                 Export Leads
//               </Button>
//               <Button className="px-1 py-2 sm:hidden flex items-center gap-2" onClick={downloadCSV}>
//                 <FiDownload className="h-5 w-5" />
//               </Button>