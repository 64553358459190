import React, { useState, Fragment } from 'react';
import { Transition } from '@headlessui/react';
import { BsCheck2 } from 'react-icons/bs';
import Button from '../Button';
import { HiOutlineXMark } from 'react-icons/hi2';

const NotesForm = ({
    note,
    setNote,
    loading,
    handleNotes,
    selectedToEdit,
    setSelectedToEdit,
}) => {
    const onSubmit = (e) => {
        e.preventDefault();
        if (selectedToEdit) {
            handleNotes(false);
        } else {
            handleNotes(true);
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            if (selectedToEdit) {
                handleNotes(false);
            } else {
                handleNotes(true);
            }
        }
    };

    return (
        <Transition
            as={Fragment}
            enter="transform transition duration-[400ms]"
            enterFrom="opacity-0 rotate-[-120deg] scale-50"
            enterTo="opacity-100 rotate-0 scale-100"
            leave="transform duration-200 transition ease-in-out"
            leaveFrom="opacity-100 rotate-0 scale-100"
            leaveTo="opacity-0 scale-95"
        >
            <form className="relative" onSubmit={onSubmit}>
                <textarea
                    required
                    name="note"
                    type="text"
                    placeholder="add note..."
                    value={note}
                    rows={4}
                    className="appearance-none bg-transparent shadow-sm block md:w-full lg:w-[23rem] w-full mt-1 px-5 py-2 border border-border rounded-t-md placeholder-gray-400 sm:text-sm focus:z-10 focus:outline-none"
                    onChange={(e) => setNote(e.target.value)}
                    onKeyDown={handleKeyDown}
                />
                <div className="md:w-full w-full bottom-0 p-2 bg-transparent border border-border rounded-b-md flex justify-end space-x-0.5">
                    <Button
                        disabled={loading.notes || (!note)}
                        loading={loading.notes}
                        type="submit"
                        kind="Outline"
                        className="mx-1"
                    >
                        <BsCheck2 />
                    </Button>
                    <Button
                        disabled={loading.notes}
                        onClick={() => {
                            setNote('');
                            setSelectedToEdit(null);
                        }}
                        kind="Outline"
                    >
                        <HiOutlineXMark />
                    </Button>
                </div>
            </form>
        </Transition>
    );
};

export default NotesForm;
