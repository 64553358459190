import React from 'react';


const CardList = ({ children }) => {
    return (
        <div className="">
            {children}
        </div>
    );
};

export default CardList;
