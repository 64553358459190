import React, { useEffect, useState } from "react";
import { motion, AnimatePresence, useAnimation } from "framer-motion";
import { menuItemVariants, menuIconVarints } from "../utils/motion";
import { HiOutlineChevronDoubleLeft, HiOutlineChevronDoubleRight } from "react-icons/hi";
import { BiSolidDashboard } from "react-icons/bi";
import { FiLogOut, FiX } from "react-icons/fi";
import { HiOutlineSpeakerphone } from "react-icons/hi";
import { PiChartLineUpLight } from "react-icons/pi";
import { CgNotes } from "react-icons/cg";
import c from "classnames";
import Header from "../components/Header";
import useAuth from "../hooks/useAuth";
import { NavLink } from "react-router-dom";
import { IoIosTimer } from "react-icons/io";

const links1 = [
  {
    href: "/",
    name: "Dashboard",
    icon: BiSolidDashboard,
  },
  {
    href: "/sales",
    name: "Leads",
    icon: PiChartLineUpLight,
  },
  {
    href: "/marketing",
    name: "Marketing",
    icon: HiOutlineSpeakerphone,
  },
  {
    href: "/release-notes",
    name: "Release Notes",
    icon: CgNotes,
  },
  {
    href: "/waitlist",
    name: "Waitlist",
    icon: IoIosTimer,
  }
];

const links2 = [
  {
    href: " ",
    name: "logout",
    icon: FiLogOut,
  },
];

export default function SidebarLayout({ children }) {
  const [isMobile, setIsMobile] = useState(false);
  const [isOpened, setIsOpened] = useState(true);

  const { logout } = useAuth();
  const controls = useAnimation();

  useEffect(() => {
    if (!isOpened) controls.start({ left: "calc(100% - 1.5rem)" });
  }, [controls, isOpened]);

  useEffect(() => {
    const handleResize = () => {
      const check = window.innerWidth <= 820;
      setIsMobile(check);
      if (check) setIsOpened(false);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);



  return (
    <div className="flex flex-row-reverse h-screen bg-background-light">
      <div className="flex flex-col flex-1 overflow-hidden">
        <Header
          className="bg-background-light border-b-2 dark:border-border"
          setIsOpened={setIsOpened}
        />
        <main className="flex-1 overflow-y-auto">{children}</main>
      </div>

      <div
        className={c(
          "dark:bg-background-4 bg-background-3 overflow-y-auto overflow-x-hidden rounded-r-[14px]",
          isMobile ? (isOpened ? 'w-full' : 'w-0') : (isOpened ? 'w-64' : 'w-16') // Adjust widths as needed
        )}
      >
        <div className="h-screen flex flex-col">
          <div className="flex flex-col items-start">
            <div className="flex flex-col items-start w-full p-3.5 md:p-4 sticky top-0 dark:bg-background-4 bg-background-3">
              <NavLink to="/" className="flex items-center relative z-50">
                <div className="font-semibold">
                  <img
                    src="/images/small-logo.png"
                    className="w-8 h-8"
                    alt="Rabbito Logo"
                  />

                </div>
                {isOpened && (
                  <span className="ml-2 md:ml-2 text-3xl text-logo  font-spartan font-semibold leading-5">
                    rabbito
                  </span>
                )}
              </NavLink>
              {/* toggle button */}
              <div
                className="flex items-center justify-end h-12 w-8 cursor-pointer absolute right-0 top-3"
                onClick={() => setIsOpened(!isOpened)}
              >
                {isMobile ? (
                  <button className="p-1 rounded focus:outline-none mr-10">
                    <FiX className="text-secondary-txt-1 font-bold w-6 h-6" />
                  </button>
                ) : (
                  isOpened ? (
                    <HiOutlineChevronDoubleLeft
                      className="dark:text-secondary-txt-1 text-white w-4 h-4 mr-4 mb-2 font-bold"
                    />
                  ) : (
                    <HiOutlineChevronDoubleRight
                      className="dark:text-secondary-txt-1 text-white w-4 h-4 font-bold"
                    />)
                )}
              </div>
            </div>

            {/* top links  */}
            <div className="mt-6 w-full">
              {links1?.map((link, i) => (
                <div
                  key={link.href}
                  className="flex items-center mb-2 cursor-pointer px-2"
                >
                  <LinkToButton
                    key={link.href}
                    href={link.href}
                    Icon={link.icon}
                    name={link.name}
                    isOpened={isOpened}
                  />
                </div>
              ))}
            </div>
          </div>

          <div className="mt-auto">
            <div className="sticky bottom-0 dark:bg-background-4 bg-background-3 ">
              {/* bottom links */}
              {links2?.map((link, i) => (
                <div
                  key={link.href}
                  className="flex items-center mb-2 cursor-pointer px-2"
                >
                  {link.name === "logout" ? (
                    <button
                      className="w-full group flex items-center text-sm font-medium focus:outline-none rounded-md px-4 py-2  hover:bg-background-inactive   duration-100"
                      onClick={logout}
                    >
                      {link.icon && (
                        <link.icon className="flex-shrink-0 -ml-1 mr-3 h-6 w-6  text-red-light-txt   group-hover:text-red-light-txt" />
                      )}
                      {isOpened && (
                        <span className="text-red-light-txt  group-hover:text-red-light-txt ">
                          {link.name}
                        </span>
                      )}
                    </button>
                  ) : (
                    <LinkToButton
                      key={link.href}
                      href={link.href}
                      Icon={link.icon}
                      name={link.name}
                      isOpened={isOpened}
                    />
                  )}
                </div>
              ))}

              <div
                className=" flex justify-center mx-4 text-sm dark:text-secondary-hover-1 text-white  p-5  border-t-2 dark:border-border-3 text-center"
              >
                <span>{isOpened ? "https://rabbito.io" : "rabbito"}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
const LinkToButton = ({ href, isOpened, Icon, name }) => {
  return (
    <NavLink to={href} className="w-full group text-sm font-medium">
      {({ isActive, isPending, ...props }) => (
        <NavLink
          {...props}
          to={href}
          className={c(
            "flex items-center focus:outline-none rounded-md py-2 px-4",
            isActive
              ? "dark:text-secondary-hover-1 dark:bg-background-3 bg-background font-semibold cursor-default"
              : " hover:text-primary-dark text-primary-txt  hover:bg-background dark:hover:bg-background-3 dark:hover:text-secondary-hover-1 duration-100 cursor-pointer"
          )}
        >
          {Icon && (
            <Icon
              className={c(
                "flex-shrink-0 -ml-1 mr-3 h-6 w-6 group-hover:text-primary-dark dark:group-hover:text-secondary-hover-1",
                isActive
                  ? "dark:text-secondary-hover-1  text-primary-dark cursor-default"
                  : " text-primary-txt cursor-pointer"
              )}
            />
          )}
          <AnimatePresence>
            {isOpened && (
              <motion.div initial="visible" variants={menuItemVariants}>
                <span>{name}</span>
              </motion.div>
            )}
          </AnimatePresence>
        </NavLink>
      )}
    </NavLink>
  );
};
