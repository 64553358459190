import React from "react";
import useSWR from "swr";
import SidebarLayout from "../layouts/SidebarLayout";
import { boardStatus } from "../constants/board";
import Skeleton from "./Skeleton";
import DatalineChart from "./chart/DatalineChart";

const Dashboard = () => {
  const { data: { data: leadStats } = {}, error: leadsError } = useSWR(
    `/crm?type=leadStats`
  );

  return (
    <SidebarLayout>
      <div className="px-3 md:px-6 lg:px-8 py-2 md:py-4 lg:py-6 ">
        <div className="flex justify-between items-center">
          <h2 className="md:text-3xl text-lg font-semibold leading-3 mt-2 text-heading-1">
            Dashboard
          </h2>
          {/* Dates & filters */}

        </div>

        <div className="mt-10">
          {!leadStats && !leadsError ? (
            <Skeleton type={"dashboard"} />
          ) : (
            <div className="grid grid-cols-2 md:grid-cols-5 gap-5">
              {Object.keys(boardStatus).map((key, index) => (
                <div
                  key={`${key}__dashboard__${index}`}
                  className={`py-5 px-4 rounded-xl bg-transparent  border border-border ${Object.keys(boardStatus).length - 1 === index
                    ? "col-span-2 md:col-span-1"
                    : ""
                    }`}
                >
                  <p className="text-sm font-medium mb-2  text-heading-2">
                    {boardStatus[key]}
                  </p>
                  <h3 className="text-3xl text-heading-2 font-semibold">
                    {leadStats?.[key] || 0}
                  </h3>

                </div>
              ))}
            </div>
          )}
        </div>
        <div>
          <DatalineChart />
        </div>
      </div>
    </SidebarLayout>
  );
};

export default Dashboard;
