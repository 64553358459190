import { BsCheckLg } from "react-icons/bs";

export default function CheckBox({ name, id, onClick, checked, hasBottomBorder }) {
  return (
    <div className={`flex items-center space-x-2   ${hasBottomBorder ? 'border-b border-border' : ''} `}>      <div className="relative flex items-center">
      <input
        type="checkbox"
        className="before:content[''] peer relative h-4 w-4 cursor-pointer ml-2 appearance-none rounded border-2 dark:border-border border-border  bg-transparent transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:border-border before:opacity-0 before:transition-opacity checked:border-primary hover:before:opacity-10"
        id={id}
        value={id}
        checked={checked}
        onChange={() => onClick({ name, id })}
      />
      <div className="pointer-events-none absolute bg-checked rounded  top-0 left-2  opacity-0 transition-opacity peer-checked:opacity-100 flex items-center justify-center">
        <BsCheckLg className="w-4 h-4 text-checked-tick" />
      </div>
    </div>
      <label
        className="flex items-center rounded cursor-pointer leading-6 font-medium text-sm text-secondary-txt my-2 "
        htmlFor={id}
        data-ripple-dark="true"
      >
        {name}
      </label>
    </div>
  );
}
