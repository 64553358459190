import React from "react";
import CheckBox from "./CheckBox";

// Function to render the loading animation
const renderLoader = columnCount => (
  <tbody className="bg-background-4 divide-y divide-border animate-pulse">
    {[1, 2, 3].map(i => (
      <tr key={i}>
        {[...Array(columnCount)].map((_, j) => (
          <td className="px-6 py-4" key={`${i}-${j}`}>
            <div className="h-4 bg-background-3 rounded w-3/4">&nbsp;</div>
          </td>
        ))}
      </tr>
    ))}
  </tbody>
);

// Function to render "No data available" message
const renderNoData = columnCount => (
  <tr className="bg-background-4 ">
    <td
      colSpan={columnCount}
      className="px-6 py-4 whitespace-nowrap text-center text-secondary-txt-1 text-sm"
    >
      No data available
    </td>
  </tr>
);

function renderCell(column, row) {
  if (column.render) {
    return column.render(row);
  }
  return row[column.key];
}

function renderTableBody(
  data,
  columns,
  actionLoader,

) {
  return data.map((row, i) => (
    <tr
      key={i.toString()}
      className={`bg-transparent mb-2 md:mb-0 ${actionLoader &&
        actionLoader.loadingSet?.has(row[actionLoader?.loadingField])
        ? "animate-pulse"
        : ""
        }`}

    >
      {columns.map((column, index) => (
        <td
          key={`${i}-${column.key.toString()}`}
          className={`px-6 mr-2 md:mr-4 py-3 whitespace-nowrap text-xs ${index === 0 || index === columns.length - 1
            ? "max-w-[12rem] md:max-w-[10rem] lg:max-w-xs p-3 text-ellipsis no-scrollbar overflow-visible ..."
            : "hidden md:table-cell"
            } text-secondary-txt table-cell relative `}
        >
          <div className="flex flex-row items-center justify-between">
            <div className="items-right truncate">
              <p className="truncate ...">{renderCell(column, row)}</p>
            </div>
          </div>
        </td>
      ))}
    </tr>
  ));
}

// DataTable component
export default function DataTable({
  columns,
  loading,
  data,
  actionLoader,
  handleCheckboxChange,
  showCheckbox = false,
  selectedRows = [],
  handleSelectAllChange,
}) {
  const columnCount = columns.length;

  return (
    <div className="flex flex-col">
      <div className="sm:-mx-6 pb-12 lg:-mx-8 ">
        <div className=" align-middle inline-block min-w-full sm:px-4 lg:px-8">
          <div className=" border-b border-border  ">
            <table className="min-w-full divide-y divide-border border-collapse w-full   ">
              <thead className=" bg-transparent">
                <tr>
                  {showCheckbox && (
                    <th className="px-2 text-left font-medium table-cell">
                      <input
                        type="checkbox"
                        className="w-4 h-4  "
                        onChange={e => handleSelectAllChange(e.target.checked)}
                      />
                    </th>
                  )}
                  {columns.map((column, index) => (
                    <th
                      key={column.key}
                      scope="col"
                      className={`px-6 py-3 text-left text-xs font-medium text-label  tracking-wider table-cell ${index === 0 || index === columns.length - 1
                        ? "w-auto p-3"
                        : "hidden md:table-cell"
                        }`}
                    >
                      {column.name}
                    </th>
                  ))}
                </tr>
              </thead>
              {loading ? (
                renderLoader(columnCount)
              ) : (
                <tbody className="bg-transparent divide-y divide-border">
                  {data?.length
                    ? renderTableBody(
                      data,
                      columns,
                      actionLoader,
                      handleCheckboxChange,
                      showCheckbox,
                      selectedRows
                    )
                    : renderNoData(columnCount)}
                </tbody>
              )}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
